<template>
  <v-container fluid>
    <CustomerTopBar :global="global"/>
     <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>
    <div class="md-card md-theme-default mt-5 mb-5 pb-5 shadow rounded-5">
        <div class="md-card-content md-card-table">
            <div>
                <div class="d-flex justify-space-between align-center ">
                    <SvgIcon class="text-2xl font-semibold " text="Duplicate Customers"></SvgIcon>
                    <v-spacer/>
                    <v-select
                        v-model="perPage"
                        :items="[50,100]"
                        :menu-props="{ bottom: true, offsetY: true }"
                        class="q-autocomplete shadow-0 m-r-3 mt-2"
                        hide-details
                        dense
                        outlined
                        @change="search"
                        placeholder="Per Page"
                        style="max-width: 100px"

                    ></v-select>
                    <v-autocomplete
                        v-model="columns"
                        :items="columnData"
                        class="q-autocomplete  shadow-0 m-r-3 mt-2"
                        hide-details
                        item-text="name"
                        item-value="value"
                        multiple
                        dense
                        outlined
                        placeholder="Table Filter"
                        style="max-width: 200px"
                    >
                    <template
                        v-if="columns.length === columnData.length"
                        v-slot:selection="{ index }"
                    >
                        <span v-if="index === 0">All Fields</span>
                    </template>
                    <template v-else v-slot:selection="{ item, index }">
                        <span v-if="index === 0">{{ item.name }}</span>
                        <span v-if="index === 1">, ...</span>
                    </template>
                    <template v-slot:prepend-inner >
                        <SvgIcon >
                        <template v-slot:icon>
                            <FilterIcon/>
                        </template>
                        </SvgIcon>
                    </template>
                    </v-autocomplete>
                    <v-btn
                        v-if="checkExportPermission($modules.clients.customers.slug) && selectedCustomer.length > 1"
                        class="export-button mt-2 white--text blue-color"
                        elevation="0"
                        height="40"
                        @click="mergePopup"
                    >
                    <SvgIcon text="Merge" >
                        <template v-slot:icon>
                        <ExportIcon/>
                        </template>
                    </SvgIcon>
                    </v-btn>
                </div>
                <br/>
                <div class="table-responsive">
                    <table class="table border-collapse " >
                    <thead>
                        <tr class="opacity-70 tr-neon tr-rounded">
                            <th>
                                <div></div>
                            </th>
                            <th v-if="checkTableColumnVisibility('name')" >
                                <div >Name</div>
                                <div class="search_column">
                                    <v-autocomplete
                                        v-model="searchParams.name"
                                        :items="names"
                                        :loading="isLoading"
                                        :search-input.sync="name"
                                        class="q-autocomplete shadow-0"
                                        filled
                                        flat
                                        hide-details
                                        item-text="name"
                                        item-value="name"
                                        label="Name"
                                        solo
                                        @change="getFilterData"
                                    />
                                </div>
                                </th>
                            <th v-if="checkTableColumnVisibility('mobile')">
                                <div >Mobile</div>
                            <div class="search_column">
                                <v-autocomplete
                                    v-model="searchParams.mobile"
                                    :items="phones"
                                    :loading="isLoading"
                                    :search-input.sync="phone"
                                    class="q-autocomplete shadow-0"
                                    filled
                                    flat
                                    hide-details
                                    item-text="mobile"
                                    item-value="mobile"
                                    label="Mobile"
                                    solo
                                    @change="getFilterData"
                                ></v-autocomplete>
                            </div>
                            </th>
                            <th v-if="checkTableColumnVisibility('email')">
                            <div >Email</div>
                            <div class="search_column">
                                <v-autocomplete
                                    v-model="searchParams.email"
                                    :items="emails"
                                    :loading="isLoading"
                                    :search-input.sync="email"
                                    class="q-autocomplete shadow-0"
                                    filled
                                    flat
                                    hide-details
                                    item-text="email"
                                    item-value="email"
                                    label="Email"
                                    solo
                                    @change="getFilterData"
                                ></v-autocomplete>
                            </div>
                            </th>
                            <th v-if="checkTableColumnVisibility('age')">
                                <div>Age</div>
                            </th>
                            <th v-if="checkTableColumnVisibility('gender')">
                                <div >Gender</div>
                            </th>
                            <th v-if="checkTableColumnVisibility('nationality')">
                                <div >Nationality</div>
                            </th>
                            <th v-if="checkTableColumnVisibility('type')">
                            <div >Type</div>
                            </th>
                            <th>
                                <div style="width: 100px" >Status</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="customer-borders">
                        <template  v-for="(customers, index) in searchDatas" >
                          <tr v-for="(data,cindex) in customers" :key="`p-${index}-${cindex}`"  :class="cindex == 0?'customer-parent':'customer-child'">
                            <th>
                              <div>
                                  <v-checkbox
                                      style="margin-top: 0px"
                                      v-model="data.isSettle"
                                      color="#66c8c8"
                                      :value="data.isSettle"
                                      @change="checkedCustomer(index,cindex)"
                                      hide-details
                                  ></v-checkbox>
                              </div>
                            </th>
                            <td v-if="checkTableColumnVisibility('name')" style="max-width: 150px; overflow: hidden; text-overflow: ellipsis;">
                                <span class="text_ellipse text-neon text-base pointer font-bold "  @click="showUserModel(data.id)">{{ data.first_name }} {{ data.last_name }} </span>
                            </td>
                            <td v-if="checkTableColumnVisibility('mobile')">
                                {{ data.mobile || "NA" }}
                            </td>
                            <td v-if="checkTableColumnVisibility('email')">
                                {{ data.email || "NA" }}
                            </td>
                            <td v-if="checkTableColumnVisibility('age')">
                                {{ data.age > 0 ? data.age : "NA" }}
                            </td>
                            <td v-if="checkTableColumnVisibility('gender')">
                                {{ data.gender || "NA" }}
                            </td>
                            <td v-if="checkTableColumnVisibility('nationality')">
                                {{ data.nationality || "NA" }}
                            </td>
                            <td v-if="checkTableColumnVisibility('type')">
                            <span v-for="(data, index) in data.customer_types.map((x) => x.name)" :key="index">
                                {{ data }}
                            </span>
                            </td>
                            <td>
                                {{ data.status_id == 1 ? "Active" : "Deactive" }}
                            </td>
                          </tr>
                        </template>
                    </tbody>
                    </table>
                </div>
            </div>
      </div>
    </div>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="4">
        <div class="d-flex align-center justify-end text-sm gap-x-2">
            <span class="">Result</span>
            <div style="width: 80px">
                <v-select
                    v-model="perPage"
                    :items="[50,100]"
                    :menu-props="{ bottom: true, offsetY: true }"
                    class="q-autocomplete text-sm"
                    flat
                    hide-details
                    solo
                    @change="search"
                ></v-select>
            </div>
            <span>Per Page</span>
        </div>
      </v-col>
    </v-row>

    <preview-customer-modal
        :isPermissionCheck="checkWritePermission($modules.clients.customers.slug)"
        v-bind="userModel"
        @close="userModel.userID = null"
        @updated="search()"
    />
    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="confirmActions"
    ></confirm-model>
     <v-dialog v-model="mergeDialoag" max-width="1000" scrollable>
      <v-card>
        <v-card-title >Merge Customer</v-card-title><v-divider></v-divider>
        <v-card-text v-if="selectedCustomer && selectedCustomer.length > 1">
          <div class="row pt-4">
            <v-col cols="12" md="12" sm="12">
              <table class="blue-text">
                <thead>
                  <tr><th colspan="3">Please select Name, Mobile and Email which you want to keep.</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="min-widt:200px;">
                      <v-radio-group
                          class=""
                          v-model="mergeCustomer.name"
                          row
                          :rules="[(v) => !!v || 'customer is required']"
                      >
                      <v-radio
                          v-for="(customer,index) in selectedCustomer"
                          :key="index"
                          :label="customer.name"
                          color="red"
                          :value="customer"
                          class="custom-radio fullwidth"
                      ></v-radio><br>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group
                          class=""
                          v-model="mergeCustomer.mobile"
                          row
                          :rules="[(v) => !!v || 'customer is required']"
                      >
                      <v-radio
                          v-for="(customer,index) in selectedCustomer"
                          :key="index"
                          :label="customer.mobile"
                          color="red"
                          :value="customer"
                          class="custom-radio fullwidth"
                      ></v-radio><br>
                      </v-radio-group>
                    </td>
                    <td>
                      <v-radio-group
                          class=""
                          v-model="mergeCustomer.email"
                          row
                          :rules="[(v) => !!v || 'customer is required']"
                      >
                      <v-radio
                          v-for="(customer,index) in selectedCustomer"
                          :key="index"
                          :label="customer.email"
                          color="red"
                          :value="customer"
                          class="custom-radio fullwidth"
                      ></v-radio><br>
                      </v-radio-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </div>
          <v-divider></v-divider>
          <div class="row">
             <div class="col-md-12">
              <p  class="font-semibold"><br><span style="display:block;color:red;">Note:</span> Please note that all orders,invoices and related records will be transferred to selected record. This action is irreversible and cannot be undone.</p>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=""
              class="ma-2"
              text
              @click="mergeDialoagClose"
          >Close</v-btn>
          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="confirm"
          >Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import FilterIcon from "@/assets/images/misc/filter-search.svg"
import PreviewCustomerModal from "@/views/Clients/Customer/PreviewCustomerModal.vue";
import ExportIcon from '@/assets/images/misc/export-icon.svg'
import CustomerTopBar from "@/views/Clients/CustomerTopBar.vue";
export default {
  props: {
    global: {
      type: Boolean,
      default: true,
    },
    eventId: {
      type: Number,
      default: null,
    },
  },
  components: {
    CustomerTopBar,
    SvgIcon,
    ConfirmModel,
    PreviewCustomerModal,
    FilterIcon,
    ExportIcon
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      flag: false,
      currentPage: 0,
      pageSize: 10,
      names: [],
      name: null,
      nationality: null,
      phones: [],
      phone: null,
      emails: [],
      email: null,
      isLoading: false,
      searchDatas: [],
      searchFlag: false,
      searchParams: {
        start_date: moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      },
      AgeRange: ["All", "18-25", "26-30", "31-35"],
      Age: [],
      toAge: null,
      fromAge: null,
      page: 1,
      perPage: 50,
      totalPages: 1,
      tags: [],
      tag: null,
      fieldData: {
        Name: "name",
        Mobile: "mobile",
        Email: "email",
        Age: "age",
        Gender: "gender",
        Nationality: "nationality",
      },
      userModel: {userID: null, type: "details"},
      btnShow: false,
      cardid: null,
      memberEntries: [],
      isMemberLoading: false,
      timeDuration: [
        {name: "All", title: "All"},
        {name: "day", title: "This Day"},
        {name: "week", title: "This Week"},
        {name: "month", title: "This Month"},
        {name: "year", title: "This Year"},
        {name: "custom", title: "Custom Duration"},
      ],
      columnData: [
        {name: "Name", value: "name"},
        {name: "Mobile", value: "mobile"},
        {name: "Email", value: "email"},
        {name: "Age", value: "age"},
        {name: "Gender", value: "gender"},
        {name: "Nationality", value: "nationality"},
        {name: "Type", value: "type"},
      ],
      columns: [
        "name",
        "mobile",
        "email",
        "age",
        "gender",
        "nationality",
        "type",
      ],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      orderBy: "id",
      orderDir: "DESC",
      selectedCustomer:[],
      selectedIndex: -1,
      mergeDialoag: false,
      mergeCustomer: {},
    };
  },

  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    date1Formatted() {
      return moment(this.searchParams.start_date, "YYYY-MM-DD").format(
          "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.end_date, "YYYY-MM-DD").format(
          "Do MMM YYYY"
      );
    },
  },
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }
    // if (!this.global) {
    this.search();
    // }
  },
  created() {
    if (typeof this.$route.params.id != "undefined") {
      this.searchParams.offline_user_id = this.$route.params.id;
    }

    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
  watch: {
    page() {
      this.search();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    nationality(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("nationality", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
    tag(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("tag", val);
    },

  },
  methods: {
    sortColumn(type) {
      if (type == this.orderBy) {
        this.orderDir = this.orderDir == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderDir = "DESC";
      }
      this.orderBy = type;
      this.search();
    },

    filterSearch(type, val) {
      if (val == "All") return;
      this.isLoading = true;
      this.$http
          .get("venues/customers/duplicates/filters?field=" + type + "&search=" + val)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.isLoading = false;
              if (type == "name") {
                this.names = response.data.data;
                this.names.unshift(val);
                this.names.unshift("All");
              } else if (type == "mobile") {
                this.phones = response.data.data;
                this.phones.unshift(val);
                this.phones.unshift("All");
              } else if (type == "nationality") {
                this.countries = response.data.data;
                this.countries.unshift(val);
                this.countries.unshift("All");
              } else if (type == "email") {
                this.emails = response.data.data;
                this.emails.unshift(val);
                this.emails.unshift("All");
              }
            }
          })

          .catch((error) => {
            this.errorChecker(error);
          });
    },

    getFilterData() {
      this.page = 1;
      this.search();
    },

    search() {
        let url = "";
        url ="&status=all" + `&order_by_custom=${this.orderBy}&sort_order=${this.orderDir}`;
        if (this.searchParams.time_intervel != "All" && this.searchParams.time_intervel != null) {
            if (this.searchParams.time_intervel == "custom") {
              this.flag = true;
            } else if (this.searchParams.time_intervel == "day") {
                this.searchParams.start_date = moment().format("YYYY-MM-DD");
            } else if (this.searchParams.time_intervel == "week") {
                this.searchParams.start_date = moment().startOf("week").format("YYYY-MM-DD");
            } else if (this.searchParams.time_intervel == "month") {
                this.searchParams.start_date = moment().startOf("month").format("YYYY-MM-DD");
            } else if (this.searchParams.time_intervel == "year") {
                this.searchParams.start_date = moment().startOf("year").format("YYYY-MM-DD");
            }
            url += "&end_date=" + this.searchParams.end_date;
            url += "&start_date=" + this.searchParams.start_date;
        }

        if (this.searchParams.name == "All") {
            delete this.searchParams.name;
        } else if (typeof this.searchParams.name != "undefined") {
            url += "&name=" + this.searchParams.name;
        }
        if (this.searchParams.email == "All") {
            delete this.searchParams.email;
        } else if (typeof this.searchParams.email != "undefined") {
            url += "&email=" + this.searchParams.email;
        }
        if (this.searchParams.mobile == "All") {
            delete this.searchParams.mobile;
        } else if (typeof this.searchParams.mobile != "undefined") {
            url += "&mobile=" + this.searchParams.mobile;
        }

        this.showLoader("Loading Customer");
        this.$http.get("venues/customers/duplicates?page=" + this.page + "&per_page=" + (this.perPage != null ? this.perPage : 10) +  url)
        .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status) {
            this.searchDatas = response.data.data;
            this.totalPages = response.data.total_pages;
            if (typeof this.searchParams.offline_user_id != "undefined") {
                this.showUserModel(this.searchParams.offline_user_id);
            }
            }
        })
        .catch((error) => {
            this.errorChecker(error);
        });
    },

    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },

    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },

    confirmActions(data) {
      if (data.type == "Deactivate" || data.type == "Activate") {
        console.log(data);
      }
      this.confirmModel.id = null;
    },

    checkedCustomer(index,cindex) {
      if(this.selectedIndex == -1){
        this.selectedIndex = index;
        let c = {
          id: this.searchDatas[index][cindex].id,
          vc_id: this.searchDatas[index][cindex].vc_id,
          name: this.searchDatas[index][cindex].name,
          email: this.searchDatas[index][cindex].email,
          mobile: this.searchDatas[index][cindex].mobile
        }
        this.selectedCustomer.push(c);
      }else{
        if(this.selectedIndex != index){
          this.selectedCustomer = [];
          /** remove existing selection from selected index */
          this.searchDatas[this.selectedIndex].map( (item) => {
              item.isSettle = false;
          })
        }
        this.selectedIndex = index;
        if(this.searchDatas[index][cindex].isSettle){
          this.searchDatas[index][cindex].isSettle = true;
          let c = {
            id: this.searchDatas[index][cindex].id,
            vc_id: this.searchDatas[index][cindex].vc_id,
            name: this.searchDatas[index][cindex].name,
            email: this.searchDatas[index][cindex].email,
            mobile: this.searchDatas[index][cindex].mobile
          }
          this.selectedCustomer.push(c);
        }else{
          const indexToRemove = this.selectedCustomer.findIndex( (item) => item.id === this.searchDatas[index][cindex].id);
          if (indexToRemove !== -1) {
            this.selectedCustomer.splice(indexToRemove, 1);
          }
        }
      }
      this.$forceUpdate();
    },

    mergePopup(){
      this.mergeCustomer = {};
      this.mergeDialoag = true;
    },
    mergeDialoagClose(){
      this.mergeCustomer = {};
      this.mergeDialoag = false;
    },
    confirm(){
      if(!this.mergeCustomer || this.mergeCustomer.name == "" || typeof this.mergeCustomer.name == "undefined"){
        this.showError("Please select name");
        return;
      }
      if(!this.mergeCustomer || this.mergeCustomer.email == "" || typeof this.mergeCustomer.email == "undefined"){
        this.showError("Please select email");
        return;
      }
      if(!this.mergeCustomer || this.mergeCustomer.mobile == "" || typeof this.mergeCustomer.mobile == "undefined"){
        this.showError("Please select mobile");
        return;
      }
      let cId = (this.mergeCustomer.name.id === this.mergeCustomer.mobile.id && this.mergeCustomer.mobile.id === this.mergeCustomer.email.id) ? this.mergeCustomer.name.id : null;
      let replacedIds = [];
      this.selectedCustomer.forEach( (customer) => {
        if(customer.id != cId){
          replacedIds.push(customer.id);
        }
      })

      let data = {
        name: this.mergeCustomer.name.name,
        mobile: this.mergeCustomer.mobile.mobile,
        email: this.mergeCustomer.email.email,
        customer_id: cId,
        replace_customer_ids: replacedIds
      }
      this.showLoader();
      this.$http
        .post(`venues/customers/merge`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Merged successfully");
            this.hideLoader();
            this.mergeDialoagClose();
            if(this.page != 1){
              this.page = 1;
            }else{
              this.search();
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });


    }
  },

};
</script>

<style lang="scss" scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.text_ellipse {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  overflow: hidden;
}
.customer-borders >tr{
  border:1px solid #ccc;
}
tr.customer-parent {
    border-bottom: 0px;
}
tr.customer-child {
    border-top: 0px;
    border-bottom: 0px;
}
tbody.customer-borders > tr:last-child {
    border-bottom: 1px solid #ccc;
}
.custom-radio.fullwidth{
  margin-bottom: 10px;
  width: 100%;
}
</style>
